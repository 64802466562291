/**index.css**/
.order-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f8f8fb;
}
.order-search-top {
  margin: 0 auto;
  margin-top: 20px;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  border-radius: 10px;
  background-color: #fff;
}
.title {
  color: #f0a93b;
  font-size: 22px;
  font-weight: 600;
}
.search-input {
  display: flex;
  align-items: center;
  margin: 35px 12px 20px 12px;
  width: 90%;
  color: #e4b753;
  border-radius: 15px;
  background: #fdf9ec;
}
.search-input img {
  padding-left: 15px;
  margin-right: 10px;
  width: 27px;
  height: 28px;
}
.search-input input {
  flex: 1;
  color: #e4b753;
  border: 0;
  background: #fdf9ec;
  outline: none;
  line-height: 40px;
}
.search-input-number {
  padding-right: 15px;
}
.search-btn {
  width: 90%;
  font-size: 16px;
  line-height: 40px;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  background: #f0aa3b;
}
.inner-box {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .inner-items {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .page-paging {
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .disable {
      opacity: 0.5;
    }
    img {
      width: 18px;
    }
  }
  .current-page {
    padding: 0 10px;
    background: #f0aa3b;
    color: #fff;
    border-radius: 4px;
  }
}
.tips {
  margin: 15px 0;
  text-align: center;
  font-size: 14px;
}
.order-info {
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 95%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 1.6vw 2.6666666667vw rgba(153, 138, 255, 0.08);
}
.order-item {
  padding-right: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  color: #000;
}
.item-label {
  padding-left: 20px;
  color: #999;
  width: 100px;
  line-height: 40px;
}
.border-bottom {
  margin-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.item-value {
  flex: 1;
  span {
    display: inline-block;
    margin-left: 10px;
    padding: 3px 5px;
    font-size: 14px;
    color: blue;
    border: 1px solid blue;
    border-radius: 20px;
  }
}
/* 订单为空 */
.order-null {
  padding: 20px 0;
  margin: 0 auto;
  margin-top: 20px;
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
.order-null-img {
  width: 150px;
  height: 125px;
}
.prompt-tips {
  margin-top: 30px;
  color: #000;
  font-size: 26px;
  font-weight: bold;
}
.search-img {
  width: 64vw;
  height: 64vw;
}
