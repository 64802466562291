@use 'sass:math';
$dWidth: 750;
@function rpx($d) {
  @return math.div($d, $dWidth) * 100 * 1vw;
}

$form-size: rpx(28);
$protocol-size: rpx(24);

$space: 5, 10, 15, 20, 30, 40, 80;

body {
  position: relative;
}

@each $v in $space {
  .pt-#{$v} {
    padding-top: rpx($v);
    box-sizing: border-box;
  }
  .pb-#{$v} {
    padding-bottom: rpx($v);
    box-sizing: border-box;
  }
  .pl-#{$v} {
    padding-left: rpx($v);
    box-sizing: border-box;
  }
  .pr-#{$v} {
    padding-right: rpx($v);
    box-sizing: border-box;
  }
  .mt-#{$v} {
    margin-top: rpx($v);
    box-sizing: border-box;
  }
  .mb-#{$v} {
    margin-bottom: rpx($v);
    box-sizing: border-box;
  }
  .ml-#{$v} {
    margin-left: rpx($v);
    box-sizing: border-box;
  }
  .mr-#{$v} {
    margin-right: rpx($v);
    box-sizing: border-box;
  }
  .w-#{$v} {
    width: rpx($v);
  }
  .h-#{$v} {
    height: rpx($v);
  }
}
.text-color-red {
  color: red;
}
.font-weight-600 {
  font-weight: 600;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.confirm-box {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .content {
    border-radius: rpx(10);
    background-color: white;
    padding: rpx(20);
    padding-top: rpx(30);
    max-width: rpx(500);
    min-width: rpx(400);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: rpx(30);
    }
    .ok-btn {
      width: rpx(300);
      height: rpx(56);
      border-radius: rpx(56);
      line-height: rpx(56);
      background-color: #f1a90e;
      color: white;
      font-size: rpx(30);
      text-align: center;
    }
  }
}

.toast-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  .toast {
    position: fixed;
    bottom: 50vh;
    box-sizing: border-box;
    padding: rpx(20);
    margin: rpx(20);
    font-size: rpx(30);
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: rpx(20);
    border: 1px solid #777;
    min-width: rpx(300);
    text-align: center;
    color: white;
    z-index: 99999;
  }
}
