.flow-customer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  padding: 5vh 5vw;
  z-index: 222;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }
  .center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(20);
    // height: rpx(260);
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    background-color: white;
    padding: rpx(20);
    .title {
      // background-color: #ff6d41;
      width: 40vw;
      // height: rpx(80);
      // line-height: rpx(80);
      text-align: center;
      font-size: rpx(42);
      // color: white;
      border-radius: rpx(60);
      margin-bottom: rpx(20);
    }
    .tel {
      font-size: rpx(80);
      margin-bottom: rpx(20);
    }
    .btn-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      .btn {
        width: rpx(180);
        height: rpx(60);
        line-height: rpx(60);
        border-radius: rpx(12);
        text-align: center;
      }
      .cancel {
        border: rpx(1) solid #999;
        color: #999;
      }
      .confirm {
        color: white;
        background-color: #ff6d41;
      }
    }
  }
}
